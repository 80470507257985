const breadcrumbsHome = { name: 'Home', pathName: '/' }
const breadcrumbsAccounts = { name: 'Contas', pathName: '/accounts' }
const breadcrumbsPublishApp = { name: 'Configurações do app', pathName: '/app' }
const breadcrumbsFriday = { name: 'Conexões friday' }
const breadcrumbsWpp = { name: 'Whatsapp' }
const breadcrumbsApp = { name: 'Publicação de apps', pathName: '/app' }

export default [
  {
    path: '/accounts',
    component: () => import('@/modules/accounts/Accounts.vue'),
    meta: {
      title: 'Contas',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
    }
  },
  {
    path: '/accounts/create',
    component: () => import('@/modules/accounts/Create.vue'),
    meta: {
      title: 'Criar conta',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/edit',
    component: () => import('@/modules/accounts/Edit.vue'),
    meta: {
      title: 'Edição de contas',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts],
      requiresAdmin: true
    },
    children: [
      {
        path: 'geral',
        component: () => import('@/modules/accounts/components/edit/General.vue'),
        params: true,
        meta: {
          title: 'Gerais',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'importacao',
        component: () => import('@/modules/accounts/components/edit/Importing.vue'),
        params: true,
        meta: {
          title: 'Importação',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'Flags',
        component: () => import('@/modules/accounts/views/flags/Flags.vue'),
        params: true,
        meta: {
          title: 'Flags',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'processamento',
        component: () => import('@/modules/accounts/components/edit/Processing.vue'),
        params: true,
        meta: {
          title: 'Processamento',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'canais-marketing',
        component: () => import('@/modules/accounts/components/edit/MarketingChannels.vue'),
        params: true,
        meta: {
          title: 'Configuração de Email/SMS',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'geolocalizacao',
        component: () => import('@/modules/accounts/components/edit/GeoLocation.vue'),
        params: true,
        meta: {
          title: 'Geo-Localização',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'dag',
        component: () => import('@/modules/accounts/components/edit/Dag.vue'),
        params: true,
        meta: {
          title: 'DAG',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'hotsite',
        component: () => import('@/modules/accounts/components/hotsite/Hotsite.vue'),
        params: true,
        meta: {
          title: 'Configurações do Hotsite',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'retailer-flags',
        component: () => import('@/modules/accounts/components/edit/RetailerFlags.vue'),
        params: true,
        meta: {
          title: 'Bandeiras',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'lojas',
        component: () => import('@/modules/accounts/components/units/Units.vue'),
        params: true,
        meta: {
          title: 'Lojas',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'stage',
        component: () => import('@/modules/accounts/components/edit/Stage.vue'),
        params: true,
        meta: {
          title: 'Stage',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      },
      {
        path: 'cashback',
        component: () => import('@/modules/accounts/components/edit/Cashback.vue'),
        params: true,
        meta: {
          title: 'Cashback',
          breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts]
        }
      }
    ]
  },
  {
    path: '/accounts/:id/reports',
    component: () => import('@/modules/accounts/components/ge-reports/GeReports.vue'),
    meta: {
      title: 'GE Reports',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/clientes',
    component: () => import('@/modules/accounts/components/platform/PlatformUsers.vue'),
    meta: {
      title: 'Clientes',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/whatsapp',
    component: () => import('@/modules/accounts/views/whatsapp-contacts/WppClients.vue'),
    meta: {
      title: 'Contatos whatsapp',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts, breadcrumbsWpp, { name: 'Contatos Whatsapp' }],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/instancias-whatsapp',
    component: () => import('@/modules/accounts/views/whatsapp-instance/Instances.vue'),
    meta: {
      title: 'Instâncias whatsapp',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts, breadcrumbsWpp, { name: 'Instâncias Whatsapp' }],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/logs',
    component: () => import('@/modules/accounts/components/logs/Logs.vue'),
    meta: {
      title: 'Logs',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts, { name: 'Logs' }],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/app-config',
    component: () => import('@/modules/accounts/components/app/AppConfig.vue'),
    meta: {
      title: 'Configuração de Aplicativo',
      breadcrumbs: [breadcrumbsHome, breadcrumbsApp, breadcrumbsPublishApp],
      requiresAdmin: true
    },
    children: [
      {
        path: 'settings',
        component: () => import('@/modules/accounts/components/app/AppSettings.vue'),
        params: true,
        meta: {
          title: 'Settings'
        }
      },
      {
        path: 'assets',
        component: () => import('@/modules/accounts/components/app/AppAssets.vue'),
        params: true,
        meta: {
          title: 'Assets'
        }
      },
      {
        path: 'metadata',
        component: () => import('@/modules/accounts/components/app/AppMetadata.vue'),
        params: true,
        meta: {
          title: 'Metadata'
        }
      },
      {
        path: 'firebase',
        component: () => import('@/modules/accounts/components/app/AppFirebase.vue'),
        params: true,
        meta: {
          title: 'Firebase'
        }
      },
      {
        path: 'creation',
        component: () => import('@/modules/accounts/components/app/CreateApp.vue'),
        params: true,
        meta: {
          title: 'Criar novo app'
        }
      }
    ]
  },
  {
    path: '/accounts/:id/friday',
    component: () => import('@/modules/accounts/views/friday/Connections.vue'),
    meta: {
      title: 'Friday',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts, breadcrumbsFriday],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/friday/criar',
    component: () => import('@/modules/accounts/views/friday/Create.vue'),
    meta: {
      title: 'Criação de conexão',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts, breadcrumbsFriday, { name: 'Cadastro de conexão' }],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/friday/editar/:id_con',
    component: () => import('@/modules/accounts/views/friday/Edit.vue'),
    meta: {
      title: 'Edição  da conexão',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts, breadcrumbsFriday, { name: 'Edição de conexão' }],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/campaigns',
    component: () => import('@/modules/accounts/components/campaigns/Campaigns.vue'),
    meta: {
      title: 'Campanhas',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts],
      requiresAdmin: true
    }
  },
  {
    path: '/accounts/:id/vouchers',
    component: () => import('@/modules/accounts/components/vouchers/Vouchers.vue'),
    meta: {
      title: 'Vouchers',
      breadcrumbs: [breadcrumbsHome, breadcrumbsAccounts],
      requiresAdmin: true
    }
  }
]
