export const microservicesUrls = {
  hulk: process.env.VUE_APP_MICROSERVICES_HULK_HTTP || 'https://apollo-platform-gateway.mercafacil.com/graphql',
  crm: process.env.VUE_APP_MICROSERVICES_CRM_HTTP || 'https://crm-svc.mercafacil.com/graphql',
  app_build: process.env.VUE_APP_MICROSERVICES_APP_BUILD_HTTP || 'https://app-build.mercafacil.com/graphql',
  app_config: process.env.VUE_APP_MICROSERVICES_APP_CONFIG_HTTP || 'https://app-config.mercafacil.com/graphql',
  external_bi: process.env.VUE_APP_MICRSERVICES_EXTERNAL_BI_HTTP || 'https://external-bi.mercafacil.com/graphql',
  federation: process.env.VUE_APP_MICRSERVICES_FEDERATION_HTTP || 'https://fed-gateway.mercafacil.com/graphql',
  friday: process.env.VUE_APP_MICRSERVICES_CONNECTIONS_HTTP || 'https://conns-svc.mercafacil.com/graphql',
  mfLogin: process.env.VUE_APP_SINGLE_LOGIN_URL || 'https://test-login.mercafacil.com:3333/#/',
  accounts: process.env.VUE_APP_MICRSERVICES_ACCOUNTS_HTTP || 'https://accounts-svc.mercafacil.com/graphql',
  asyncJobs: process.env.VUE_APP_ASYNC_JOBS_HTTP || 'https://apollo-platform-gateway.mercafacil.com/graphql'
}

export default {
  install(Vue, { env }) {
    Vue.prototype.$microservicesUrls = Vue.microservicesUrls = microservicesUrls
  }
}
