import { defineStore } from 'pinia'
import { apolloProvider } from '@/apollo-provider.js'
import { QUERY_LIST_INDUSTRIES } from '@/modules/industry/graphql'
import { microservicesUrls } from '@/plugins/microservices/microservices.js'

export const useIndustryStore = defineStore('industry', {
  state: () => ({
    industries: [],
    selectedOffersGroup: {
      name: '',
      offerType: '',
      modality: '',
      groupKey: '',
      offers: []
    },
    offer: {
      _id: '',
      name: '',
      start_date: '',
      end_date: '',
      proposal_deadline: '',
      offer_type: '',
      modality: '',
      offers_schedule_id: '',
      units: '',
      unit_tags: '',
      products_group: false,
      industry: {
        name: '',
        _id: ''
      },
      general: [],
      takepay: [],
      nextbuycashback: [],
      cashback: [],
      percentage: []
    }
  }),
  getters: {
    industryById: state => id => state.industries.find(industry => industry._id === id),
    industryByName: state => name => state.industries.find(industry => industry.name.includes(name)),
    industryNamesAndIds: state => state.industries.map(industry => ({ name: industry.name, _id: industry._id }))
  },
  actions: {
    async fetchIndustries() {
      try {
        const result = await apolloProvider.defaultClient.query({
          query: QUERY_LIST_INDUSTRIES,
          context: {
            uri: microservicesUrls['accounts']
          },
          variables: {
            pagination: {
              page: 1,
              pageSize: 10,
              search: null,
              sort: '',
              sortDirection: 'DESC'
            }
          }
        })

        if (result.data.listIndustries.data) {
          this.industries = result.data.listIndustries.data
        }
      } catch (error) {
        console.error(error)
      }
    },
    setSelectedOffersGroup(value) {
      this.selectedOffersGroup = value
    },
    resetSelectedOffersGroup() {
      this.selectedOffersGroup = {
        name: '',
        offerType: '',
        modality: '',
        groupKey: '',
        offers: []
      }
    },
    setOffer(value) {
      this.offer = JSON.parse(JSON.stringify(value))
    },
    resetOffer() {
      this.offer = {
        _id: '',
        name: '',
        start_date: '',
        end_date: '',
        proposal_deadline: '',
        offer_type: '',
        modality: '',
        offers_schedule_id: '',
        units: '',
        unit_tags: '',
        products_group: false,
        industry: {
          name: '',
          _id: ''
        },
        general: [],
        takepay: [],
        nextbuycashback: [],
        cashback: [],
        percentage: []
      }
    }
  }
})
