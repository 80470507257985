import Vue from 'vue'
import VueGtag from 'vue-gtag'
import Vuelidate from 'vuelidate'
import Moment from 'moment-timezone'
import VueTheMask from 'vue-the-mask'
import VueMomentLib from 'vue-moment-lib'
import GSignInButton from 'vue-google-signin-button'
import MfVueComponents from '@mf-node/mf-vue-components'
import '@/assets/styles/global.scss'

import App from '@/App.vue'
import router from '@/router'
import { isDev } from '@/apollo'
import { activateHotjar } from '@/external-libs'
import { apolloProvider } from './apollo-provider'

import vuetify from '@/plugins/vuetify'
import Confirm from '@/plugins/confirm/confirm.js'
import VJsoneditor from 'v-jsoneditor'
import Snackbar from '@/plugins/snackbar/snackbar.js'
import Authentication from '@/plugins/authentication/authentication.js'
import Microservices from '@/plugins/microservices/microservices.js'
import Alert from '@/plugins/alert/alert.js'
import ErrorDialog from '@/plugins/errorDialog/errorDialog.js'

import logMessage from '@/helpers/logMessage'

import filterCnpj from '@/filters/cnpj.js'
import filterTruncate from '@/filters/truncate.js'
import filterLocaleString from '@/filters/localeString'
import filterCurrency from '@/filters/currency'
import filterDate from '@/filters/date'

import excel from 'vue-excel-export'
import draggable from 'vuedraggable'
import VueShepherd from 'vue-shepherd'
import 'shepherd.js/dist/css/shepherd.css'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/plugins/i18n'
import VueDragscroll from 'vue-dragscroll'
import VueBus from './plugins/event-bus'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueCompositionAPI)
Vue.config.productionTip = false

// Moment
Moment.locale('pt-BR')
const moment = Moment.tz.setDefault('America/Bahia')
Vue.use(VueMomentLib, { moment })
Vue.use(Microservices, { env: process.env })
Vue.use(VueShepherd)
Vue.use(Microservices, { env: process.env })
Vue.use(GSignInButton)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VJsoneditor)
Vue.use(Snackbar)
Vue.use(VueTheMask)
Vue.use(Authentication)
Vue.use(logMessage)
Vue.use(MfVueComponents)
Vue.use(Confirm, { vuetify })
Vue.use(Alert, { vuetify })
Vue.use(ErrorDialog, { vuetify })
Vue.use(Microservices, { env: process.env })
Vue.use(excel)
Vue.use(draggable)
Vue.use(VueDragscroll)
Vue.use(VueBus)

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title

  // Store redirect if user was already logged with expired session
  if (from.path !== '/login' && from.path !== '/expired-session' && from.path !== '/' && to.path === '/expired-session') {
    window.localStorage.setItem('redirectAfterLogin', from.fullPath)
  }
  // Store redirect if user is not logged
  if (!Vue.isLogged() && !['/login', '/expired-session', '/restrict-access'].includes(to.path)) {
    window.localStorage.setItem('redirectAfterLogin', to.fullPath)
  }
  next()
})

// mixins
Vue.mixin({
  methods: {
    getSafe(fn) {
      try {
        return fn()
      } catch (e) {
        // No-op
      }
    }
  }
})

// filters
Vue.filter('cnpj', filterCnpj)
Vue.filter('truncate', filterTruncate)
Vue.filter('localeString', filterLocaleString)
Vue.filter('currency', filterCurrency)
Vue.filter('date', filterDate)

/* external libs */
if (!isDev) activateHotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=') // hotjar

const idGA4 = process.env.VUE_APP_TOKEN_GA

const isProdOrBeta = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development'

const loggedUser = Vue.getLoggedUser()

const debugAndTracking = {
  autoTracking: {
    exception: true
  },
  debug: {
    enabled: !isProdOrBeta,
    sendHitTask: isProdOrBeta
  }
}

const ga4Params = {
  config: { id: idGA4 },
  ...debugAndTracking
}

const dimensions = [{ field: 'dimension2', value: loggedUser.name }]

if (loggedUser.username) {
  ga4Params.set = dimensions
}

Vue.use(VueGtag, ga4Params, router)

new Vue({
  router,
  i18n,
  vuetify,
  apolloProvider,
  pinia,
  render: h => h(App)
}).$mount('#app')
