const breadcrumbsHome = { name: 'Home', pathName: '/' }

export default [
  {
    path: '/external-bi/clients-base',
    component: () => import('@/modules/external_bi/ClientsBasePBI.vue'),
    meta: {
      title: 'BI - Base de clientes',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/commercial',
    component: () => import('@/modules/external_bi/CommercialPBI.vue'),
    meta: {
      title: 'BI - Comercial',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/commercial-2023',
    component: () => import('@/modules/external_bi/Commercial2023PBI.vue'),
    meta: {
      title: 'BI - Comercial - 2023',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/engineering-jira',
    component: () => import('@/modules/external_bi/EngineeringJIRAPBI.vue'),
    meta: {
      title: 'BI - Eng - JIRA',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/retailer-app',
    component: () => import('@/modules/external_bi/RetailerAppPBI.vue'),
    meta: {
      title: 'BI - Varejista - App',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/marketing',
    component: () => import('@/modules/external_bi/MarketingPBI.vue'),
    meta: {
      title: 'BI - Marketing',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/partnerships',
    component: () => import('@/modules/external_bi/CommercialPartnershipsPBI.vue'),
    meta: {
      title: 'Parcerias',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/partnerships-referrals',
    component: () => import('@/modules/external_bi/CommercialPartnershipsReferralsPBI.vue'),
    meta: {
      title: 'Parcerias - Indicantes',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/dash-ops',
    component: () => import('@/modules/external_bi/DashOpsPBI.vue'),
    meta: {
      title: 'Dash OPS',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/erp-integrations',
    component: () => import('@/modules/external_bi/ERPIntegrationsPBI.vue'),
    meta: {
      title: 'ERPs',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/support-tickets',
    component: () => import('@/modules/external_bi/SupportTicketsPBI.vue'),
    meta: {
      title: 'Tickets de Suporte',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/ti-deployment',
    component: () => import('@/modules/external_bi/TIDeploymentPBI.vue'),
    meta: {
      title: 'Implantação TI',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/platform-access',
    component: () => import('@/modules/external_bi/PlatformAccessPBI.vue'),
    meta: {
      title: 'Acessos na Plataforma',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/mercapromo-cashback',
    component: () => import('@/modules/external_bi/MercapromoCashbackPBI.vue'),
    meta: {
      title: 'Aptos à Cashback',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/product-catalog',
    component: () => import('@/modules/external_bi/ProductCatalogPBI.vue'),
    meta: {
      title: 'Catálogo de Produtos',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/dags-monitor',
    component: () => import('@/modules/external_bi/DAGsMonitorPBI.vue'),
    meta: {
      title: 'Monitoramento de DAGs',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/cs-trade-engagement',
    component: () => import('@/modules/external_bi/CSTradeEngagementLooker.vue'),
    meta: {
      title: 'Engajamento de Trade por analista',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/trade-engagement',
    component: () => import('@/modules/external_bi/IndustryEngagementLooker.vue'),
    meta: {
      title: 'Engajamento de Trade',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/mercapromo-results',
    component: () => import('@/modules/external_bi/IndustryMercapromoResultsLooker.vue'),
    meta: {
      title: 'Resultados Mercapromo',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/base-expansion',
    component: () => import('@/modules/external_bi/IndustryBaseExpansionLooker.vue'),
    meta: {
      title: 'Expansão de Base',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/mercapromo-conversion',
    component: () => import('@/modules/external_bi/IndustryMercapromoConversionLooker.vue'),
    meta: {
      title: 'Conversão Mercapromo',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/promotrade-optin',
    component: () => import('@/modules/external_bi/IndustryPromotradeOptinLooker.vue'),
    meta: {
      title: 'Pitch Opt-in Promotrade',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/multi-industry-analytics',
    component: () => import('@/modules/external_bi/MultiIndustryPBI.vue'),
    meta: {
      title: 'Analytics Multi-indústria',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/external-bi/okr-2025',
    component: () => import('@/modules/external_bi/OKR2025PBI.vue'),
    meta: {
      title: 'OKR 2025',
      breadcrumbs: [breadcrumbsHome]
    }
  }
]
