import gql from 'graphql-tag'

export const MUTATION_INSERT_MERCATRADE = gql`
  mutation addMercatrade($input: AddMercatradeInput!) {
    addMercatrade(data: $input) {
      message
      success
    }
  }
`

export const QUERY_GET_MERCATRADE_OFFERS_PAGINATED = gql`
  query getMercatradeOffers($input: GetIndMercatradeOffersInput!, $pagination: PaginationParams!) {
    getMercatradeOffers(input: $input, pagination: $pagination) {
      data {
        _id
        action
        db_name
        title
        offers_schedule_id
        processed
        id_external
        status
        updated_at
        offer_type
        proposal_deadline
        campaigns {
          sms {
            send_date
            send_time
          }
        }
        current_error {
          date
          message
          traceback
          type
        }
        industry {
          _id
          name
        }
        retailers {
          discounts {
            start_date
            end_date
          }
          name
          segmentation
          segmentation_days
          segmentation_offer
          segmentation_type
        }
      }
      count
    }
  }
`

export const MUTATION_INSERT_MERCAPROMO = gql`
  mutation AddMercapromo($input: AddMercapromoInput!) {
    addMercapromo(data: $input) {
      message
      success
    }
  }
`

export const QUERY_GET_MERCAPROMO_OFFERS_PAGINATED = gql`
  query getMercapromoOffers($input: GetIndMercapromoOffersInput!, $pagination: PaginationParams!) {
    getMercapromoOffers(input: $input, pagination: $pagination) {
      data {
        _id
        db_name
        start_date
        end_date
        action
        processed
        name
        updated_at
        offer_type
        id_external
        status
        proposal_deadline
        offers_schedule_id
        industry {
          _id
          name
        }
        current_error {
          date
          message
          traceback
          type
        }
      }
      count
    }
  }
`

export const MUTATION_INDUSTRY_OFFERS_LOG_REPORT = gql`
  mutation CreateIndustryOfferLogsReport(
    $emails: [String!]!
    $tradeType: [IndustryOfferLogsTradeTypes!]!
    $fileFormat: FileFormatType
    $offerTypes: [IndustryOfferLogsOfferType!]
    $actions: [ActionEnum!]
    $status: [IndustryOfferLogsStatusEnum!]
    $startCampaignPeriod: TradePeriodInput
    $actionPeriod: TradePeriodInput
    $industryIds: [ID!]
    $search: String
  ) {
    createIndustryOfferLogsReport(
      emails: $emails
      tradeType: $tradeType
      fileFormat: $fileFormat
      offerTypes: $offerTypes
      actions: $actions
      status: $status
      startCampaignPeriod: $startCampaignPeriod
      actionPeriod: $actionPeriod
      industryIds: $industryIds
      search: $search
    ) {
      id
    }
  }
`

export const MUTATION_CREATE_MERCATRADE_CALENDAR = gql`
  mutation createMercatradeCalendarReport(
    $baseCalendar: [JSON!]
    $baseClient: [JSON!]
    $emails: [String!]!
    $offerType: OfferTypeEnum
    $fileFormat: FileFormatType
  ) {
    createMercatradeCalendarReport(baseCalendar: $baseCalendar, baseClient: $baseClient, emails: $emails, offerType: $offerType, fileFormat: $fileFormat) {
      id
    }
  }
`

export const QUERY_LIST_INDUSTRIES = gql`
  query listIndustries($pagination: PaginationParams!) {
    listIndustries(pagination: $pagination) {
      count
      data {
        _id
        accountId
        origin
        source
        name
      }
    }
  }
`

export const QUERY_SEGMENT_BY_INDUSTRY_ID = gql`
  query listSegmentsByIndustry($input: ListSegmentsByIndustryInput!, $pagination: PaginationParams!) {
    listSegmentsByIndustry(input: $input, pagination: $pagination) {
      count
      data {
        _id
        name
        description
      }
    }
  }
`

export const QUERY_LIST_INDUSTRY_SEGMENT_CRITERIA = gql`
  query listIndustrySegmentCriteria($input: ListIndustrySegmentCriteriaInput!, $pagination: PaginationParams!) {
    listIndustrySegmentCriteria(input: $input, pagination: $pagination) {
      count
      data {
        _id
        name
        description
        industry {
          _id
          name
        }
        created_at
        updated_at
        created_by_email
        updated_by_email
      }
    }
  }
`

export const QUERY_INDUSTRY_SEGMENT_CRITERIA_BY_ID = gql`
  query industrySegmentCriteria($input: SearchIndSegmentCriteriaInput!) {
    industrySegmentCriteria(input: $input) {
      _id
      name
      description
      industry {
        _id
        name
      }
      reference_segment {
        _id
        name
        description
      }
      created_at
      updated_at
      created_by_email
      updated_by_email
    }
  }
`

export const MUTATION_UPSERT_INDUSTRY_SEGMENT_CRITERIA = gql`
  mutation upsertIndustrySegmentCriteria($input: UpsertIndSegmentCriteriaInput!) {
    upsertIndustrySegmentCriteria(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_DELETE_IND_SEGMENT_CRITERIA = gql`
  mutation deleteIndustrySegmentCriteria($input: DeleteIndSegmentCriteriaInput!) {
    deleteIndustrySegmentCriteria(input: $input) {
      success
      message
    }
  }
`

export const QUERY_LIST_INDUSTRY_SEGMENT_PRODUCTS = gql`
  query listIndustrySegmentProducts($input: ListIndustrySegmentProductsInput!, $pagination: PaginationParams!) {
    listIndustrySegmentProducts(input: $input, pagination: $pagination) {
      count
      data {
        _id
        name
        description
        industry {
          _id
          name
        }
        created_at
        updated_at
        created_by_email
        updated_by_email
      }
    }
  }
`

export const QUERY_INDUSTRY_SEGMENT_PRODUCTS_BY_ID = gql`
  query industrySegmentProducts($input: SearchIndSegmentProductsInput!) {
    industrySegmentProducts(input: $input) {
      _id
      name
      description
      eans
      industry {
        _id
        name
      }
      created_at
      updated_at
      created_by_email
      updated_by_email
    }
  }
`

export const QUERY_INDUSTRY_OFFER = gql`
  query IndustryOffer($industryOfferId: ID!, $modality: EnumModality!) {
    industryOffer(id: $industryOfferId, modality: $modality) {
      _id
      name
      start_date
      end_date
      modality
      offer_type
      offers_schedule_id
      products_group
      proposal_deadline
      units
      unit_tags
      industry {
        _id
        name
      }
      general {
        ean
        label_pdv
        maximum_per_client
        minimum_per_client
        price_1
        price_2
        product_name
        rebate
      }
      nextbuycashback {
        cashback_absolute_discount
        cashback_expiration_date
        ean
        label_pdv
        max_coupons
        minimum_per_client
        product_name
      }
      percentage {
        ean
        label_pdv
        maximum_per_client
        minimum_per_client
        percentage_discount
        product_name
      }
      takepay {
        ean
        label_pdv
        maximum_per_client
        take
        pay
        product_name
      }
    }
  }
`

export const QUERY_INDUSTRY_OFFER_LOG = gql`
  query IndustryOffer($industryOfferId: ID!, $modality: EnumModality!) {
    industryOffer(id: $industryOfferId, modality: $modality) {
      _id
      action
      name
      updated_at
      logs {
        date
        error {
          date
          message
          traceback
          type
        }
        alert {
          date
          message
          type
        }
        new_data
        old_data
        traceback
        type
        user {
          email
          name
        }
      }
    }
  }
`

export const MUTATION_UPSERT_INDUSTRY_SEGMENT_PRODUCTS = gql`
  mutation upsertIndustrySegmentProducts($input: UpsertIndSegmentProductsInput!) {
    upsertIndustrySegmentProducts(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_DELETE_IND_SEGMENT_PRODUCTS = gql`
  mutation deleteIndustrySegmentProducts($input: DeleteIndSegmentProductsInput!) {
    deleteIndustrySegmentProducts(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_DELETE_INDUSTRY_OFFERS = gql`
  mutation deleteIndustryOffers($input: DeleteIndustryOffersInput!) {
    deleteIndustryOffers(input: $input) {
      success
      message
    }
  }
`
export const MUTATION_REPROCESS_INDUSTRY_OFFER = gql`
  mutation ReprocessIndustryOffers($input: ReprocessIndustryOffersInput!) {
    reprocessIndustryOffers(input: $input) {
      message
      success
    }
  }
`

export const MUTATION_UPDATE_INDUSTRY_OFFER = gql`
  mutation UpdateIndustryOffers($input: UpdateIndustryOffersInput!) {
    updateIndustryOffers(input: $input) {
      message
      success
    }
  }
`
