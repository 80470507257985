import Vue from 'vue'
const bus = new Vue()

bus.set = function(event, handler) {
  bus.$on(event, handler)
  bus.$emit(event, handler)
}

export default function install(Vue) {
  Object.defineProperties(Vue.prototype, {
    $bus: {
      get() {
        return bus
      }
    }
  })
}
